import { LitElement, css, html, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';

// this gets inlined by Parcel
import fs from 'fs';
const mycss = fs.readFileSync(__dirname + '/element.css', 'utf8');

export default class VideoPlayer extends LitElement {
	static styles = css`
		${unsafeCSS(mycss)}
	`;

	@property()
	preload?: string;
	@property({ type: Boolean })
	autoplay?: boolean;
	@property({ type: Boolean })
	loop?: boolean;
	@property()
	src?: string;
	@property()
	poster?: string;
	@property()
	extraCSS?: string;
	@property({ type: Boolean })
	muted = true;

	@state()
	protected playing = false;

	@query('video')
	protected _video!: HTMLVideoElement;

	protected render() {
		return html`
      <div class="video-player flex flex-col h-full w-full">
				<style>${this.extraCSS}</style>
        <video class="grow" 
					controlslist="nodownload nofullscreen noremoteplayback" 
					disablePictureInPicture 
					disableRemotePlayback 
					@click=${this.togglePlay} 
					muted=${this.muted} 
					loop=${this.loop} 
					autoplay=${this.autoplay} 
					preload=${this.preload} 
					src=${this.src} 
					poster=${this.poster} 
					@playing=${this.play}
					@ended=${this.pause}>
				</video>
				<div id="controls" class="flex shrink-0">
					<button @click=${this.play} class=${this.playing ? 'hidden' : ''}>
						<slot name="play-button"></slot>
					</button>
					<button @click=${this.pause} class=${this.playing ? '' : 'hidden'}>
						<slot name="pause-button"></slot>
					</button>
					<slot name="control-gap"></slot>
					<button @click=${this.mute} class=${this.muted ? 'hidden' : ''}>
						<slot name="mute-button"></slot>
					</button>
					<button @click=${this.unmute} class=${this.muted ? '' : 'hidden'}>
						<slot name="unmute-button"></slot>
					</button>
				</div>
			</div>
    `;
	}

	protected play(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.playing = true;
		// weird bug(?) in Chrome which ignores the attribute in the HTML when it's added dynamically
		// https://stackoverflow.com/a/51755171/4321944
		this._video.muted = this.muted;
		this._video.play();
	}

	protected pause(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.playing = false;
		this._video.pause();
	}

	protected togglePlay(e: MouseEvent) {
		if (this.playing) {
			this.pause(e);
		} else {
			this.play(e);
		}
	}

	protected mute(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.muted = true;
		this._video.muted = this.muted;
	}

	protected unmute(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.muted = false;
		this._video.muted = this.muted;
	}
}
