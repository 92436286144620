import { Fragment, h } from 'preact';
import { forwardRef, useEffect, useState, useMemo } from 'preact/compat';

export interface DrawingProps {
	width: number;
	height: number;
	config?: Partial<DrawingConfig>;
	extraCSS?: string;
	onDrawDone?: (imageData: string) => void;
}
export interface DrawingConfig {
	brushSizes: number[];
	colors: string[];
	colorNames: string[];
	backgroundImage?: string;
}
const defaultConfig: DrawingConfig = {
	brushSizes: [5, 10, 15], // [5, 10, 20, 35, 50],
	colors: ['#CE481C', '#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#FFFFFF'],
	colorNames: ['Orange', 'Black', 'Red', 'Green', 'Blue', 'Yellow', 'Cyan', 'Magenta', 'White'],
};

const Drawing = forwardRef(({ width, height, extraCSS, config: configProp, onDrawDone }: DrawingProps, fRef) => {
	const config = { ...defaultConfig, ...configProp };

	const [canvas, setCanvas] = useState<HTMLCanvasElement | null>();
	const [brushSize, setBrushSize] = useState(config.brushSizes[Math.floor(config.brushSizes.length / 2)]);
	const [color, setColor] = useState(config.colors[0]);
	const context = useMemo(() => canvas?.getContext('2d'), [canvas]);

	useEffect(() => {
		if (!canvas) return;
		// if this isn't set to absolute values very very weird things happen
		const bounds = canvas.getBoundingClientRect();
		canvas.width = bounds.width;
		canvas.height = bounds.height;
	}, [canvas]);

	useEffect(() => {
		if (!canvas || !context) return;
		// add eventlistener so we set the size again when the canvas size changes
		const onResize = () => {
			const bounds = canvas.getBoundingClientRect();
			canvas.width = bounds.width;
			canvas.height = bounds.height;
		};

		let isDrawing = false;
		let lastX = 0;
		let lastY = 0;
		let drawDoneTimeout: number | undefined;

		const draw = (newX: number, newY: number): void => {
			// console.log('DRAW', [lastX, lastY], [newX, newY], e);
			context.strokeStyle = color;
			context.lineWidth = brushSize;
			context.lineCap = 'round';
			context.beginPath();
			context.moveTo(lastX, lastY);
			context.lineTo(newX, newY);
			context.stroke();
			lastX = newX;
			lastY = newY;
			clearTimeout(drawDoneTimeout);
			drawDoneTimeout = setTimeout(() => {
				onDrawDone?.(createResizedImage(canvas, width, height));
			}, 100);
		};

		const onMouseDown = (e: MouseEvent): void => {
			// console.log('onMouseDown');
			isDrawing = true;
			const newX = e.offsetX;
			const newY = e.offsetY;
			lastX = newX;
			lastY = newY;
		};
		const onTouchStart = (e: TouchEvent): void => {
			e.preventDefault();
			isDrawing = true;
			const bounds = canvas.getBoundingClientRect();
			// console.log('onTouchStart', e.touches[0].clientX - bounds.left, e.touches[0].clientY - bounds.top);
			[lastX, lastY] = [e.touches[0].clientX - bounds.left, e.touches[0].clientY - bounds.top];
		};
		const onMouseMove = (e: MouseEvent): void => {
			// console.log('onMouseMove');
			if (!isDrawing) return;
			const newX = e.offsetX;
			const newY = e.offsetY;
			draw(newX, newY);
		};
		const onTouchMove = (e: TouchEvent): void => {
			e.preventDefault();
			if (!isDrawing) return;
			const bounds = canvas.getBoundingClientRect();
			// console.log('onTouchMove', e.touches[0].clientX - bounds.left, e.touches[0].clientY - bounds.top);
			draw(e.touches[0].clientX - bounds.left, e.touches[0].clientY - bounds.top);
		};
		const drawStop = () => {
			// console.log('onMouseUpOrOut');
			isDrawing = false;
		};

		window.addEventListener('resize', onResize);
		canvas.addEventListener('mousedown', onMouseDown);
		canvas.addEventListener('touchstart', onTouchStart);
		canvas.addEventListener('touchmove', onTouchMove);
		canvas.addEventListener('mousemove', onMouseMove);
		canvas.addEventListener('mouseup', drawStop);
		canvas.addEventListener('mouseout', drawStop);
		canvas.addEventListener('touchend', drawStop);
		canvas.addEventListener('touchcancel', drawStop);
		return () => {
			window.removeEventListener('resize', onResize);
			canvas.removeEventListener('mousedown', onMouseDown);
			canvas.removeEventListener('touchstart', onTouchStart);
			canvas.removeEventListener('touchmove', onTouchMove);
			canvas.removeEventListener('mousemove', onMouseMove);
			canvas.removeEventListener('mouseup', drawStop);
			canvas.removeEventListener('mouseout', drawStop);
			canvas.removeEventListener('touchend', drawStop);
			canvas.removeEventListener('touchcancel', drawStop);
		};
	}, [canvas, context, color, brushSize, height, width, onDrawDone]);

	// this ensures that the canvas doesn't get re-rendered on every change and thus losing its state
	const Canvas = useMemo(() => {
		return (
			<canvas
				id="myCanvas"
				ref={setCanvas}
				className="touch-none bg-contain bg-no-repeat bg-gray-700"
				style={{
					aspectRatio: `${width}/${height}`,
					// ideally this would be a relative URL, but we don't know that
					cursor: "url('https://tangiatwitchext-frontend.cf.tangia.co/assets/pen.svg') 0 36, auto",
					backgroundImage: `url('${config.backgroundImage}')`,
				}}
			/>
		);
	}, [width, height, config.backgroundImage]);

	return (
		<div id="drawing" className="flex flex-col relative">
			<style>{extraCSS}</style>
			{Canvas}
			<div id="controls" className="mt-2 flex place-content-around items-center">
				<button
					id="reset-btn"
					className="px-3 ml-2 h-full rounded-full aspect-square text-lg text-gray-200 border-2 border-gray-700  bg-gray-900 hover:bg-gray-700 shadow shadow-black"
					onClick={() => context!.clearRect(0, 0, canvas!.width, canvas!.height)}
				>
					↺
				</button>

				<div id="brushSize" className="mx-2 min-h-[32px] flex rounded-full bg-white items-center shadow shadow-black">
					{config.brushSizes.map((size) => (
						<button
							key={size}
							className="rounded-full mx-2 my-1 bg-black outline-orange-600 outline outline-0"
							onClick={() => setBrushSize(size)}
							style={{
								width: `${size}px`,
								height: `${size}px`,
								outlineWidth: brushSize === size ? '3px' : '0',
							}}
						></button>
					))}
				</div>

				<div id="color" className="mr-2 flex flex-wrap rounded-full px-2 bg-white items-center shadow shadow-black">
					{config.colors.map((c) => (
						<button
							key={c}
							className="rounded-full m-2 h-[15px] w-[15px] outline-gray-600 outline"
							onClick={() => setColor(c)}
							style={{
								backgroundColor: c,
								outlineWidth: color === c ? '4px' : '2px',
							}}
						></button>
					))}
				</div>
			</div>
		</div>
	);
});
Drawing.displayName = 'Drawing';

const createResizedImage = (canvas: HTMLCanvasElement, new_width: number, new_height: number) => {
	// Create a new canvas element with the same aspect ratio as the original canvas
	const new_canvas = document.createElement('canvas');
	new_canvas.width = new_width;
	new_canvas.height = new_height;

	// Draw the original canvas onto the new canvas
	const ctx = new_canvas.getContext('2d');
	ctx!.drawImage(canvas, 0, 0, new_width, new_height);

	// Convert the new canvas to a base64-encoded PNG image
	const dataURL = new_canvas.toDataURL('image/png');
	return dataURL;
};

export { Drawing };
