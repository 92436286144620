// this is a HTML custom element wrapper around AudioTrimmer to make it usable via
// HTMX. It's a form-associated element so that the resulting cut will be submitted as part of the sourounding form

import { AudioTrimmer, IAudioTrimmerProps } from './AudioTrimmer';
import * as React from 'preact/compat';
import retargetEvents from 'react-shadow-dom-retarget-events';

// this gets inlined by Parcel
import fs from 'fs';
const mycss = fs.readFileSync(__dirname + '/element.css', 'utf8');

export default class AudioTrimmerInput extends HTMLElement {
	static formAssociated = true;
	constructor() {
		super();
		this.internals = this.attachInternals();
	}

	internals: ElementInternals;
	mountPoint?: HTMLElement;
	audioUrl?: string | null;
	minCut?: number | null;
	maxCut?: number | null;

	connectedCallback() {
		this.audioUrl = this.getAttribute('audioUrl');
		if (!this.audioUrl) {
			return;
		}
		this.minCut = Number(this.getAttribute('minCut'));
		this.maxCut = Number(this.getAttribute('maxCut'));
		this.mountPoint = document.createElement('div');
		// ID is hardcoded in component!
		this.mountPoint.id = 'waveform';
		const shadowRoot = this.attachShadow({ mode: 'open' });
		const style = document.createElement('style');
		style.innerHTML = mycss;
		shadowRoot.appendChild(style);
		shadowRoot.appendChild(this.mountPoint);

		this.createElement();
		retargetEvents(shadowRoot);
	}

	createElement() {
		React.render(
			React.createElement(AudioTrimmer, {
				audioUrl: this.audioUrl!,
				maxCut: this.maxCut,
				minCut: this.minCut!,
				onTrim: (start, end) => this.onTrim(start, end),
			}),
			this.mountPoint!
		);
	}

	onTrim(start: number, end: number) {
		const formData = new FormData(this.internals.form ?? undefined);
		formData.set('start', String(start));
		formData.set('end', String(end));
		this.internals.setFormValue(formData);
	}

	static get observedAttributes() {
		return ['audioUrl', 'minCut', 'maxCut'];
	}

	attributeChangedCallback(name: string, oldValue: any, newValue: any) {
		if (name === 'audioUrl') this.audioUrl = newValue;
		if (name === 'minCut') this.minCut = newValue;
		if (name === 'maxCut') this.maxCut = newValue;
		this.createElement();
	}
}
