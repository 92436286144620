// this is a HTML custom element wrapper around AudioTrimmer to make it usable via
// HTMX. It's a form-associated element so that the resulting cut will be submitted as part of the sourounding form

import * as React from 'preact/compat';
import retargetEvents from 'react-shadow-dom-retarget-events';
import { Drawing, DrawingConfig } from './Drawing';

// this gets inlined by Parcel
import fs from 'fs';
const mycss = fs.readFileSync(__dirname + '/element.css', 'utf8');

export default class DrawingInput extends HTMLElement {
	static formAssociated = true;
	constructor() {
		super();
		this.internals = this.attachInternals();
	}

	internals: ElementInternals;
	mountPoint?: HTMLElement;
	config?: DrawingConfig;
	width: number = 100;
	height: number = 100;
	extraCSS?: string;
	name?: string;

	connectedCallback() {
		this.config = JSON.parse(this.getAttribute('config') ?? 'null');
		this.width = Number(this.getAttribute('width'));
		this.height = Number(this.getAttribute('height'));
		this.extraCSS = this.getAttribute('extraCSS') ?? undefined;
		this.name = this.getAttribute('name') ?? undefined;

		this.mountPoint = document.createElement('div');

		const shadowRoot = this.attachShadow({ mode: 'open' });
		const style = document.createElement('style');
		style.innerHTML = mycss;
		shadowRoot.appendChild(style);
		shadowRoot.appendChild(this.mountPoint);

		this.createElement();
		retargetEvents(shadowRoot);
	}

	createElement() {
		React.render(
			React.createElement(Drawing, {
				extraCSS: this.extraCSS,
				width: this.width,
				height: this.height,
				config: this.config,
				onDrawDone: (d) => this.onDrawDone(d),
			}),
			this.mountPoint!
		);
	}

	onDrawDone(imageData: string) {
		const formData = new FormData(this.internals.form ?? undefined);
		formData.set(this.name || 'img', imageData);
		this.internals.setFormValue(formData);
	}

	static get observedAttributes() {
		// we don't support dynamic changes
		return [];
	}

	attributeChangedCallback(name: string, oldValue: any, newValue: any) {
		// we don't support dynamic changes
	}
}
