import { LitElement, css, html, unsafeCSS } from 'lit';
import { property, query, state } from 'lit/decorators.js';

// this gets inlined by Parcel
import fs from 'fs';
const mycss = fs.readFileSync(__dirname + '/element.css', 'utf8');

export default class AudioPlayer extends LitElement {
	static styles = css`
		${unsafeCSS(mycss)}
	`;

	@property()
	preload?: string;
	@property()
	src?: string;

	@state()
	private playing = false;

	@query('audio')
	_audio!: HTMLAudioElement;

	protected render() {
		return html`
      <div class="h-full w-full">
        <audio preload=${this.preload} src=${this.src} @ended=${this.pause}></audio>
        <button @click=${this.play} class=${this.playing ? 'hidden' : 'w-full h-full'}>
          <slot name="play-button"></slot>
        </button>
        <button @click=${this.pause} class=${this.playing ? 'w-full h-full' : 'hidden'}>
          <slot name="pause-button"></slot>
        </button>
      </p>
    `;
	}

	protected play(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.playing = true;
		this._audio.play();
	}

	protected pause(e: MouseEvent) {
		e.preventDefault();
		e.stopImmediatePropagation();
		this.playing = false;
		this._audio.pause();
	}
}
