import '@lit-labs/virtualizer';
import { LitElement, TemplateResult, html } from 'lit';
import { property, state } from 'lit/decorators';
import { unsafeStatic, withStatic } from 'lit/static-html.js';

export default class VirtualizedList extends LitElement {
	@property()
	innerStyle?: string;
	@property()
	scroller?: boolean;

	@state()
	protected _slottedHTML: TemplateResult[] = [];

	protected render() {
		return html`
			<lit-virtualizer scroller=${this.scroller} style=${this.innerStyle} .items=${this._slottedHTML} .renderItem=${(i: TemplateResult) => i}> </lit-virtualizer>
			<slot style="display:none" @slotchange=${this.handleSlotchange}></slot>
		`;
	}

	handleSlotchange(e: Event) {
		const slot = e.target as HTMLSlotElement;
		const newNodes = slot?.assignedElements({ flatten: true }).map((e) => withStatic(html)`${unsafeStatic(e.outerHTML)}`);
		if (!newNodes || !newNodes.length) return;
		this._slottedHTML = newNodes;
		// console.log("slotted", this._slottedHTML.length);
		slot.remove();
	}
}
