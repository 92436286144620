import { Fragment, h } from 'preact';
import { forwardRef, useRef, useState, useReducer, useEffect, useImperativeHandle } from 'preact/compat';
import type { Region } from 'wavesurfer.js/src/plugin/regions';
import { faExclamationTriangle, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from './spinner';

import type WaveSurfer from 'wavesurfer.js';
import type RegionsPlugin from 'wavesurfer.js/src/plugin/regions';

const formatSecondsToTimeString = (seconds: number) => {
	const sec = Math.round(seconds);
	// Convert seconds to minutes and seconds
	const minutes = Math.floor(seconds / 60);
	const secondsLeft = sec - minutes * 60;

	// Add leading zero if seconds are less than 10
	const secondsString = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;

	return `${minutes}:${secondsString}`;
};

const Ellipses = () => (
	<svg width="4" height="28" viewBox="0 0 4 28" fill="currentColor" className="text-orange-300" xmlns="http://www.w3.org/2000/svg">
		<circle cx="2" cy="2" r="2" fill="inherit" />
		<circle cx="2" cy="10" r="2" fill="inherit" />
		<circle cx="2" cy="18" r="2" fill="inherit" />
		<circle cx="2" cy="26" r="2" fill="inherit" />
	</svg>
);

export interface IAudioTrimmerProps {
	className?: string;
	audioUrl?: string;
	minCut?: number;
	maxCut?: number | null;
	onTrim?: (start: number, end: number) => void;
}
const AudioTrimmer = forwardRef(({ className, audioUrl, minCut = 3, maxCut = null, onTrim }: IAudioTrimmerProps, fRef) => {
	const wavesurfer = useRef<any>(null);
	const [container, setContainer] = useState<HTMLElement | null>();

	interface SongData {
		error: string | null;
		loading: boolean;
		isPlaying: boolean;
		duration: number;
		trimStart: number;
		trimEnd: number;
	}
	const [songData, setSongData] = useReducer(
		(state: SongData, newState: Partial<SongData>) => ({
			...state,
			...newState,
		}),
		{
			error: null,
			loading: true,
			isPlaying: false,
			duration: 0,
			trimStart: 0,
			trimEnd: 0,
		}
	);
	useEffect(() => {
		if (!audioUrl || !container) {
			return;
		}
		console.log('init wavesurfer', audioUrl);

		const initWaveSurfer = async () => {
			// @ts-ignore: TS wants to add `.default` but that's wrong with a dynamic import
			const WaveSurfer: WaveSurfer = await import('wavesurfer.js/dist/wavesurfer.js');
			// @ts-ignore: same
			const RegionsPlugin: typeof RegionsPlugin = await import('wavesurfer.js/dist/plugin/wavesurfer.regions.js');

			if (wavesurfer.current) {
				wavesurfer.current.destroy();
			}

			const gray500 = `rgba(130, 130, 132, 1)`;
			const orange500a20 = `rgba(206, 72, 28, 0.2)`;
			wavesurfer.current = WaveSurfer.create({
				container: container,
				waveColor: gray500,
				progressColor: `rgba(130, 130, 132, 1)`,
				barWidth: 3,
				barHeight: 1,
				barGap: 2,
				barRadius: 3,
				cursorWidth: 2,
				cursorColor: 'white',
				height: 71,
				responsive: true,
				normalize: true,
				plugins: [
					RegionsPlugin.create({
						regionsMaxLength: 1,
						maxRegions: 1,
						regions: [
							{
								id: 'trim',
								start: 0,
								end: maxCut ? maxCut : 10,
								loop: false,
								color: orange500a20,
								// @ts-ignore: allow to set minLength and maxLength
								minLength: minCut,
								maxLength: maxCut ? maxCut : undefined,
							},
						],
						dragSelection: false,
					}),
				],
			});

			// Load audio
			wavesurfer.current.load(audioUrl);

			// On ready
			wavesurfer.current.on('ready', () => {
				// Set initial region positions
				const start = 0;
				const end = maxCut ? maxCut : wavesurfer.current.getDuration();

				const region = wavesurfer.current.regions.list.trim;
				region.update({
					start,
					end,
				});
				setSongData({
					loading: false,
					trimStart: start,
					trimEnd: end,
					duration: wavesurfer.current.getDuration(),
				});
			});

			// On error
			wavesurfer.current.on('error', (error: Error) => {
				setSongData({
					loading: false,
					error: 'Error loading audio',
				});
			});

			// On Play
			wavesurfer.current.on('play', () => {
				setSongData({
					isPlaying: true,
				});
			});

			wavesurfer.current.on('pause', () => {
				setSongData({
					isPlaying: false,
				});
			});

			wavesurfer.current.on('region-updated', (e: Region) => {
				setSongData({
					trimStart: e.start,
					trimEnd: e.end,
				});
			});
		};

		initWaveSurfer();
	}, [audioUrl, minCut, maxCut, container]);

	useImperativeHandle(fRef, () => ({
		trimStart: songData.trimStart,
		trimEnd: songData.trimEnd,
	}));

	useEffect(() => {
		onTrim && onTrim(songData.trimStart, songData.trimEnd);
	}, [songData.trimStart, songData.trimEnd, onTrim]);

	return (
		<div className={className}>
			{songData.loading ? (
				<div className="flex items-center justify-center  rounded-lg bg-gray-700 w-full h-full">
					<span className="text-sm inline-flex items-center gap-2">
						<Spinner />
						Loading...
					</span>
				</div>
			) : songData.error ? (
				<div className="flex items-center justify-center  rounded-lg bg-gray-700 w-full h-full">
					<span className="text-sm inline-flex items-center gap-2 text-yellow-500">
						<FontAwesomeIcon icon={faExclamationTriangle} />
						{songData.error}
					</span>
				</div>
			) : null}
			<div className={`flex items-center gap-4 ${(songData.loading || songData.error) && 'opacity-0'}`}>
				<button
					className="mx-auto m-4 w-[12px] h-[12px] shrink-0"
					onClick={() => {
						const region = Object.values(wavesurfer.current.regions.list)[0] as any;
						if (!region) return;
						if (songData.isPlaying) {
							wavesurfer.current.pause();
						} else {
							region.play();
						}
					}}
				>
					<FontAwesomeIcon icon={songData.isPlaying ? faPause : faPlay} />
				</button>
				<div className="px-[10px] w-full audio-wave mb-8 mt-2">
					<div id="waveform" ref={(r) => setContainer(r)} className="w-full rounded-lg bg-gray-700 relative">
						<span
							className="absolute -bottom-1 rounded-md bg-gray-700 py-1 px-2 text-xs block transform translate-y-full -translate-x-[calc(50%+9px)]"
							style={{
								left: `${(songData.trimStart / songData.duration) * 100}%`,
							}}
						>
							{formatSecondsToTimeString(songData.trimStart || 0)}
						</span>

						<span
							className="absolute -bottom-1 rounded-md bg-gray-700 py-1 px-2 text-xs block transform translate-y-full -translate-x-[calc(50%-9px)]"
							style={{
								left: `${(songData.trimEnd / songData.duration) * 100}%`,
							}}
						>
							{formatSecondsToTimeString(songData.trimEnd || 0)}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
});
AudioTrimmer.displayName = 'AudioTrimmer';

export { AudioTrimmer };
