import AudioPlayer from './web-components/audio-player/player';
import AudioTrimmerInput from './web-components/audio-trimmer/AudioTrimmerInput';
import DrawingInput from './web-components/drawing/DrawingInput';
import VideoPlayer from './web-components/video-player/player';
import VirtualizedList from './web-components/virtual-list/virtual-list';

window.customElements.define('audio-trimmer-input', AudioTrimmerInput);
window.customElements.define('drawing-input', DrawingInput);
window.customElements.define('audio-player', AudioPlayer);
window.customElements.define('video-player', VideoPlayer);
window.customElements.define('virtualized-list', VirtualizedList);
