import type htmxType from 'htmx.org';

import 'htmx.org/dist/ext/ajax-header.js';
import 'htmx.org/dist/ext/class-tools.js';
import 'htmx.org/dist/ext/disable-element.js';
import 'htmx.org/dist/ext/event-header.js';
import 'htmx.org/dist/ext/head-support.js';
import 'htmx.org/dist/ext/json-enc.js';
import 'htmx.org/dist/ext/loading-states.js';
import 'htmx.org/dist/ext/method-override.js';
import 'htmx.org/dist/ext/morphdom-swap.js';
import 'htmx.org/dist/ext/multi-swap.js';
import 'htmx.org/dist/ext/path-deps.js';
import 'htmx.org/dist/ext/preload.js';
import 'htmx.org/dist/ext/remove-me.js';
import 'htmx.org/dist/ext/restored.js';
import 'htmx.org/dist/ext/sse.js';
import 'htmx.org/dist/ext/ws.js';

import './custom-elements';

const htmx = (window as any).htmx as typeof htmxType;
htmx.config.allowEval = false;
// this enables cookies to be used
htmx.config.withCredentials = true;
// htmx.config.requestClass = 'grouprequestActive';
// htmx.config.defaultSwapStyle = 'morphdom';
// (htmx.config as any).inlineScriptNonce = 'myscript'; // wrong types :(
// htmx.config.getCacheBusterParam

const Twitch = window.Twitch;
let twitchAuth: Twitch.ext.Authorized | undefined;
let twitchCtx: Partial<Twitch.ext.Context> | undefined;
let twitchExtVisible: boolean | undefined;
if (Twitch) {
	// https://dev.twitch.tv/docs/extensions/reference#javascript-helper
	Twitch.ext.onContext((ctx) => {
		twitchCtx = { ...twitchCtx, ...ctx };
		// segment.setStreamInfo(ctx);
		Twitch.ext.onVisibilityChanged((visible: boolean) => {
			twitchExtVisible = visible;
			// NOTE: only available in >=v2.4.0 (i.e. not yet on prod)
			htmx.trigger(window.document.body, visible ? 'tangiaTwitchVisible' : 'tangiaTwitchInvisible', {});
		});
	});
	Twitch.ext.onAuthorized((auth) => {
		//console.log("The JWT that will be passed to the EBS is", auth.token);
		//console.log("The channel ID is", auth.channelId);
		twitchAuth = auth;
		htmx.trigger(window.document.body, 'tangiaTwitchAuth', {});
		Twitch.ext.onContext((ctx) => {
			twitchCtx = { ...twitchCtx, ...ctx };
			// segment.setStreamInfo(ctx);
			Twitch.ext.onVisibilityChanged((visible: boolean) => {
				twitchExtVisible = visible;
				// NOTE: only available in >=v2.4.0 (i.e. not yet on prod)
				htmx.trigger(window.document.body, visible ? 'tangiaTwitchVisible' : 'tangiaTwitchInvisible', {});
			});
		});
	});
}

/*
var requestConfig = {
    boosted: eltIsBoosted,
    parameters: filteredParameters,
    unfilteredParameters: allParameters,
    headers:headers,
    target:target,
    verb:verb,
    errors:errors,
    withCredentials: etc.credentials || requestAttrValues.credentials || htmx.config.withCredentials,
    timeout:  etc.timeout || requestAttrValues.timeout || htmx.config.timeout,
    path:path,
    triggeringEvent:event
};
*/
// this hooks into all HTMX requests and modifies them
document.body.addEventListener('htmx:configRequest', function (evt: any) {
	if (evt.detail.path.startsWith('/')) {
		// staging
		if (window.location.href.includes('m1sy1tatqtoy9lesb0qgrta1kfs8c2')) {
			evt.detail.path = 'https://staging.tangiatwitchext.pages.dev' + evt.detail.path;
		} else if (window.location.href.includes('127.0.0.1') || window.location.href.includes('localhost') || window.location.href.includes('twitch-ext-local')) {
			// keep relative during development
		} else {
			// default to production
			evt.detail.path = 'https://tangiatwitchext-frontend.cf.tangia.co' + evt.detail.path;
		}
	}
	evt.detail.headers['Authorization'] = 'Bearer ' + (twitchAuth?.token || 'MISSING-TOKEN');
	evt.detail.headers['X-Twitch-Features'] = JSON.stringify(Twitch?.ext?.features || {});
	evt.detail.headers['X-Twitch-Context'] = JSON.stringify(twitchCtx || {});
	evt.detail.headers['X-Twitch-Visible'] = `${twitchExtVisible}`;

	// workaround for https://github.com/bigskysoftware/htmx/issues/1323
	if (evt.target.tagName === 'FORM') {
		const formData = new FormData(evt.target); // this triggers a formdata event, which is handled by our custom elements
		// add the form data as request parameters
		for (const pair of formData.entries()) {
			const name = pair[0];
			const value = pair[1];
			const parameters = evt.detail.parameters;
			// for multivalued form fields, FormData.entries() may contain multiple entries with the same name
			if (parameters[name] == null) {
				parameters[name] = [value]; // single element array
			} else if (Array.isArray(parameters[name]) && !parameters[name].includes(value)) {
				parameters[name].push(value);
			}
		}
	}
});

// extension that passes through event details. Useful for custom events like the twitch purchase
htmx.defineExtension('event-detail-header', {
	onEvent: function (name, evt) {
		if (name === 'htmx:configRequest') {
			if (evt.detail.triggeringEvent) {
				const { elt, ...eventDetails } = evt.detail.triggeringEvent.detail || {};
				evt.detail.headers['X-Event-Details'] = JSON.stringify(eventDetails);
			}
		}
	},
});

// each new img gets a fallback
document.body.addEventListener('htmx:afterProcessNode', function (evt: any) {
	(evt.detail.elt as HTMLElement).querySelectorAll('img').forEach((img) => {
		const fallbackCSS = img.getAttribute('data-fallback-css');
		if (fallbackCSS === null) {
			return;
		}
		img.addEventListener('error', function () {
			img.classList.add(...fallbackCSS.split(' '));
		});
	});
});

document.body.addEventListener('htmx:swapError', function (evt: any) {
	console.error('swapError', evt);
});
document.body.addEventListener('htmx:targetError', function (evt: any) {
	console.error('targetError', evt);
});
document.body.addEventListener('htmx:oobErrorNoTarget', function (evt: any) {
	console.error('targetError', evt);
});

document.body.addEventListener('tangia:buyTwitchProduct', async function (evt: any) {
	console.log('tangia:buyTwitchProduct', evt.detail);
	try {
		window.document.body.classList.add('opacity-50');
		// segment?.track("Ext Purchase Started", { interaction: i, interactionInfo, buyerVars });
		const bitsTransaction = new Promise<object>((complete, cancel) => {
			Twitch.ext.bits.onTransactionComplete(complete);
			Twitch.ext.bits.onTransactionCancelled(cancel);
		});
		Twitch.ext.bits.useBits(evt.detail.ProductID);
		// this contains the signed JWT of the buy transaction
		const tx = await bitsTransaction;
		htmx.trigger(window.document.body, 'tangia:purchaseCompleted', { ...evt.detail, ...tx });
		// segment?.track("Ext Purchase Completed", { interaction: i, interactionInfo, buyerVars, product: p });
	} catch (e: any) {
		htmx.trigger(window.document.body, 'tangia:purchaseCancelled', { ...evt.detail, ...e });

		// segment?.track("Ext Purchase Failed", { interaction: i, interactionInfo, buyerVars, error: e });
	} finally {
		window.document.body.classList.remove('opacity-50');
		Twitch.ext.bits.onTransactionComplete(() => {});
		Twitch.ext.bits.onTransactionCancelled(() => {});
	}
});

document.body.addEventListener('tangia:purchaseCompleted', async function (evt: any) {
	// console.log('tangia:purchaseCompleted', evt.detail);
});

document.body.addEventListener('tangia:purchaseCancelled', async function (evt: any) {
	console.log('tangia:purchaseCancelled', evt.detail);
});

document.body.addEventListener('tangia:requestIdShare', async function (evt: any) {
	console.log('tangia:requestIdShare');
	Twitch.ext.actions.requestIdShare();
});
